import { Theme, ThemeProvider } from "@mui/material";
import { ConfigProvider, theme } from "antd";
import React from "react";
import GlobalStoreContext from "../src/GlobalStoreContext";
import DarkTheme from "../src/components/UI/Themes/DarkTheme";
import LightTheme from "../src/components/UI/Themes/LightTheme";

interface Props {
    children: any;
    themeOverride?: Theme;
}

const GlobalTheme: React.FC<Props> = ({ children, themeOverride }) => {
    const globalStoreContext = React.useContext(GlobalStoreContext);

    const themeLogic = () => {
        if (themeOverride) {
            return themeOverride;
        } else {
            if (globalStoreContext.userInfo?.user_preferences?.theme === "dark") {
                return DarkTheme;
            } else {
                return LightTheme;
            }
        }
    };

    const antThemeLogic = () => {
        if (themeOverride) {
            if (themeOverride === DarkTheme) {
                return theme.darkAlgorithm;
            } else {
                return theme.defaultAlgorithm;
            }
        } else {
            if (globalStoreContext.userInfo?.user_preferences?.theme === "dark") {
                return theme.darkAlgorithm;
            } else {
                return theme.defaultAlgorithm;
            }
        }
    };

    return (
        // MUI Theme provider
        <ThemeProvider theme={themeLogic()}>
            {/* Ant Design Theme Provider */}
            <ConfigProvider
                theme={{
                    token: {
                        colorPrimary: "#FF9E00",
                        colorLink: "#FF9E00",
                    },
                    algorithm: antThemeLogic(),
                }}
            >
                {children}
            </ConfigProvider>
        </ThemeProvider>
    );
};

export default GlobalTheme;
